module.exports = {
  "name": "printemps",
  "client": "Printemps Haussmann",
  "codifName": "M001",
  "description": "Projet MobiGeo pour l'application Printemps Haussmann",
  "validLangs": ["en", "fr", "zh"],
  "version": {
    "mainVersion": "4.0.0",
    "buildVersion": {
      "iOS": "28",
      "android": "27",
      "web": "212"
    }
  },
  "native": {
    "author": "Mobile-Spot",
    "backgroundColor": "0xffffff",
    "iosAppStoreUrl": "https://itunes.apple.com/app/printemps-haussmann/id853771459",
    "iosBuildTarget": "12.0",
    "playStoreUrl": "https://play.google.com/store/apps/details?id=com.printemps.haussmann",
    "androidFadeSplashScreenDuration": 750,
    "androidTargetSdkVersion": 34,
    "androidCompileSdkVersion": 34,
    "app": "app-react",
    "appleTeamId": "2BAP3P29V2",
    "name": {
      "default": "Haussmann DEV"
    },
    "id": "com.printemps.haussmann.dev",
    "appleAppId": "1012404293",
    "fingerprints": ["91:D0:3E:95:6E:2F:F5:12:A1:47:F0:7D:E6:5F:CD:AF:FB:41:B7:49:FD:49:4C:CB:84:28:DC:86:4E:93:B0:65"]
  },
  "undeliveredFolders": ["source", "exports"],
  "cordova": true,
  "crypto": false,
  "customFileUrlScheme": "msfile",
  "relatedRepos": [{
    "src": "git@bitbucket.org:mobilespotdev/cordova.git",
    "postCommands": ["cd cordova && npm install"],
    "branch": "inte/printemps/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo.git",
    "postCommands": ["cd mobigeo && npm install"],
    "branch": "inte/printemps/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/mobigeo-customs-master.git",
    "dest": "mobigeo/mobigeo-customs",
    "branch": "inte/printemps/main",
    "postCommands": ["cd mobigeo/mobigeo-customs && npm install"]
  }, {
    "src": "git@bitbucket.org:mobilespotdev/app-react.git",
    "branch": "inte/printemps/main"
  }, {
    "src": "git@bitbucket.org:mobilespotdev/data-master.git",
    "dest": "app-react/app-customs",
    "branch": "inte/printemps/main",
    "postCommands": ["cd app-react && yarn", "cd app-react && npm run set-env dev", "cd app-react && npm run download-assets", "cd app-react && npm run download-data-files", "cd app-react && npm run update-mobigeo"]
  }],
  "web": {
    "url": "ph.ms-dev.mobile-spot.com"
  }
};