const defaultConf = require('./config-default');
const LOCAL_NODE_BACKEND_DOMAIN = 'http://localhost:3082';
const BO_URL = 'boph4.0-data.ms-dev.mobile-spot.com';
module.exports = require('deepmerge')(defaultConf, {
  ENV: 'dev',
  BO_URL,
  // no trailing '/'
  BO_PATH: "~/printemps/printemps-haussmann/".concat(BO_URL),
  BO_SERVER_ALIAS: 'ms-dev.mobilespot.prodleni.local',
  // "ms-dev.mobilespot.prodleni.local", //
  BO_SSH_USER: 'legacy',
  // "legacy", // 'www',
  BO_SSH_PORT: '43122',
  // '22'

  DEPLOY_PATH: '~/printemps/printemps-haussmann/ph.ms-dev.mobile-spot.com',
  GOOGLE_ANALYTICS_ID: 'UA-41991492-1',
  GOOGLE_ANALYTICS_ID_V4: 'G-9QEBLRGS90',
  projectId: 217901982433,
  // GCM/FCM send id
  appId: 'D040C-3AC4B',
  // pushwoosh appid

  SEND_EXPORTED_NOTES_MAIL: {
    WS_URL: 'https://services.mobile-spot.com/api/exportNotesByMail',
    // 'http://localhost:3096/api/exportNotesByMail',
    EXPEDITOR: 'no-reply@mobile-spot.com',
    KEY: 'zpgZkgiz845gjzFzi'
  },
  ADDITIONAL_DEFAULTSRC_CSP: (Array.isArray(defaultConf.ADDITIONAL_DEFAULTSRC_CSP) ? defaultConf.ADDITIONAL_DEFAULTSRC_CSP : []).concat([]),
  CHECK_NETWORK_URL: "https://".concat(BO_URL, "/online.txt"),
  SYNCHRONIZED_FAVORITES: {
    MODE: 'peering',
    // (peering or login)
    URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN, "/synchro-favorites")
  },
  REAL_TIME: {
    URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN)
  },
  SSO: {
    SSO_REQUEST_URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN, "/sso-request")
  },
  SOCIAL: {
    TWITTER: {
      POST_PER_PAGE: 10
    },
    YOUTUBE: {
      POST_PER_PAGE: 5
    },
    FACEBOOK: {
      POST_PER_PAGE: 5
    },
    FACEBOOK2: {
      POST_PER_PAGE: 5
    },
    INSTAGRAM: {
      POST_PER_PAGE: 5
    },
    URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN, "/social")
  },
  SECURED_PDF: {
    SECURED_PDF_REQUEST_URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN, "/secured-pdf-viewer")
  },
  PUBLIC_PDF: {
    PUBLIC_PDF_REQUEST_URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN, "/public-pdf-viewer")
  },
  CONTRIBUTIONS_FEED: {
    URL: "".concat(LOCAL_NODE_BACKEND_DOMAIN, "/contributions-feed")
  },
  TOU_LINK: {
    en: "https://".concat(BO_URL, "/cgu/cgu_en.html"),
    fr: "https://".concat(BO_URL, "/cgu/cgu_fr.html"),
    zh: "https://".concat(BO_URL, "/cgu/cgu_zh.html")
  }
});