export default {
  id: 'fr',
  common: {
    appTitle: 'Printemps Haussmann',
    colon: ' : ',
    questionMark: ' ?',
    noResult: 'Aucun résultat',
    yes: 'Oui',
    no: 'Non',
    ok: 'OK',
    confirm: 'Valider',
    cancel: 'Annuler',
    close: 'Fermer',
    keep: 'Conserver',
    delete: 'Effacer',
    camera: 'Appareil photo',
    gallery: 'Galerie',
    chooseLang: 'Choix de la langue',
    addToFavorites: 'Ajouter aux favoris',
    addNote: 'Ajouter une note',
    editNote: 'Editer la note',
    defaultPlaceLabel: 'Plan',
    noNetworkTitle: 'Réseau indisponible',
    noNetwork: 'Nous vous prions de réessayer plus tard',
    loading: 'Veuillez patienter...',
    showAll: 'Afficher sur la carte',
    noPlacesToShowOnMap: 'Aucun emplacement à afficher sur la carte',
    termsOfUse: "Conditions d'utilisation",
    information: 'Informations',
    display: 'Affichage',
    switch2Columns: "Basculer sur un mode d'affichage adapté à la largeur de votre écran ? (conseillé)",
    share: 'Partager',
    goBack: 'Retour',
    goToHome: "Revenir à l'accueil",
    goToRootCategory: "Revenir à l'accueil de la rubrique",
    login: 'Identification',
    goToFavorites: 'Voir vos favoris',
    goToInbox: 'Accéder aux messages',
    goToSearch: 'Aller à la page de recherche',
    goToFilter: 'Filtrer le contenu',
    goToSynopticAgenda: 'Voir le synoptique',
    goBackToAgenda: 'Retour à la liste des événements',
    openMenu: 'Ouvrir le menu',
    relatedContent: 'Contenu lié',
    contributions: 'Contributions',
    feedback: 'Retour',
    rateThisSession: 'Évaluez cette session',
    seeMore: 'voir plus ...',
    seeLess: 'voir moins ...',
    accept: 'Accepter',
    deny: 'Refuser',
    continue: 'Continuer',
    noData: 'Aucune donnée disponible',
    navigationDenied: 'Vous devez être authentifié pour accéder à cette page',
    navigationDeniedwithoutLogin: "Vous n'avez pas le droit d'accéder à cette page.",
    save: 'Enregistrer',
    saving: 'Enregistrement...',
    saved: 'Enregistré',
    invalidAuth: 'Session invalide ou expirée',
    updateFailed: "L'enregistrement a échoué",
    error: 'Une erreur est survenue',
    contact: 'Contacter',
    contactedOn: 'Contacté le ',
    contactConfirmMessage: 'Une demande de contact en votre nom va être envoyée par courriel à cette personne. Si elle accepte, elle vous contactera en retour, par courriel ou téléphone.',
    contactErrorTitle: 'Echec de la demande de contact',
    contactError: 'Nous vous prions de réessayer plus tard.',
    fetchError: 'Echec lors de la récupération des données',
    mandatory: ' <small><i> - obligatoire</i></small>',
    smartbannerViewButton: 'TÉLÉCHARGER'
  },
  formErrors: {
    missing: 'Valeur manquante',
    invalid: 'Valeur invalide'
  },
  location: {
    feature: 'Localisation',
    yourLocation: 'Votre position'
  },
  disclaimer: {
    text: 'Des cookies sont utilisés sur ce plan interactif pour effectuer des statistiques anonymes d’utilisation.'
  },
  share: {
    mail: 'Partager par email',
    facebook: 'Partager via Facebook',
    linkedin: 'Partager via LinkedIn',
    twitter: 'Partager via Twitter',
    positionShare: 'Position partagée',
    // favorite position share description
    shareFail: 'Echec du partage',
    linkedinShareDone: 'Page partagée sur votre profil LinkedIn',
    linkedinShareFail: 'Echec du partage via LinkedIn',
    facebookShareDone: 'Page partagée sur votre profil Facebook',
    facebookShareFail: 'Echec du partage via Facebook'
  },
  data: {
    brands: {
      singular: 'Marque',
      plural: 'Marques',
      title: 'Marques'
    },
    brand_categories: {
      singular: 'Catégorie de marques',
      plural: 'Catégories de marques',
      title: 'Marques'
    },
    exhibitors: {
      singular: 'Exposant',
      plural: 'Exposants',
      title: 'Exposants',
      searchPlaceholder: 'Exposants'
    },
    exhibitor_categories: {
      singular: "Catégorie d'exposants",
      plural: "Catégories d'exposants",
      title: 'Exposants'
    },
    kleads_contacts: {
      singular: 'contact',
      plural: 'contacts'
    },
    restaurants: {
      singular: 'Restaurant',
      plural: 'Restaurants',
      title: 'Restaurants'
    },
    restaurant_categories: {
      singular: 'Catégorie de restaurants',
      plural: 'Catégories de restaurants',
      title: 'Restaurants'
    },
    monuments: {
      singular: 'À visiter',
      plural: 'À visiter',
      title: 'À visiter'
    },
    happenings: {
      singular: 'Animation',
      plural: 'Animations',
      title: 'Animations'
    },
    services: {
      singular: 'Service',
      plural: 'Services',
      title: 'Services'
    },
    service_categories: {
      singular: 'Catégorie de services',
      plural: 'Catégories de services',
      title: 'Services'
    },
    aircrafts: {
      singular: 'Aéronef',
      plural: 'Aéronefs',
      title: 'Aéronefs'
    },
    aircraft_categories: {
      singular: "Catégorie d'aéronefs",
      plural: "Catégories d'aéronefs",
      title: 'Aéronefs'
    },
    events: {
      singular: 'Événement',
      plural: 'Programme',
      title: 'Programme',
      subevents: 'Interventions'
    },
    event_categories: {
      plural: 'Programme',
      title: 'Programme'
    },
    conferences: {
      singular: 'Conférence',
      plural: 'Conférences'
    },
    speakers: {
      singular: 'Conférencier',
      plural: 'Conférenciers',
      title: 'Conférenciers',
      moderators: 'Modérateurs'
    },
    papers: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    paper_categories: {
      singular: 'Abstract',
      plural: 'Abstracts',
      title: 'Abstracts'
    },
    newproducts: {
      singular: 'Produit',
      plural: 'Produits',
      title: 'Produits'
    },
    newproduct_categories: {
      singular: 'Catégorie de produits',
      plural: 'Catégories de produits',
      title: 'Produits'
    },
    animations: {
      plural: 'Animations',
      title: 'Animations',
      singular: 'Animation',
      go: 'Voir sur le plan'
    },
    favoritepositions: {
      plural: 'Positions',
      singular: 'Position'
    },
    participants: {
      singular: 'Contacts',
      plural: 'Contacts',
      title: 'Contacts'
    },
    contacted_participants: {
      singular: 'Mes demandes de contact',
      plural: 'Mes demandes de contact',
      title: 'Mes demandes de contact'
    },
    flights: {
      singular: 'Vol',
      plural: 'Vols'
    },
    flights_schedule: {
      locale: 'fr',
      // wtf ?
      longDateFormat: 'L HH:mm:ss',
      title: 'Agenda des vols',
      refresh: 'Rafraîchir',
      lastFetched: 'Dernière update',
      unknownAircraft: 'Aéronef inconnu',
      notScheduledYet: 'Horaire à venir',
      errorWhileFetching: 'Une erreur est survenue',
      lastRefreshed: 'Dernière mise à jour : ',
      noResults: 'Les vols ne sont pas encore programmés'
    },
    infos: {
      singular: "Horaires d'ouverture"
    },
    docunits: {
      singular: 'Casier presse',
      plural: 'Casiers presse'
    }
  },
  dataListDialog: {
    defaultTitle: 'todo default title'
  },
  size: {
    bytes: ' octets',
    kilobytes: ' ko',
    megabytes: ' mo'
  },
  update: {
    modalTitle: 'Mise à jour',
    update_detected: 'Mettre à jour les données ?',
    cancel_update: 'Annuler la mise à jour',
    update_done: 'Mise à jour terminée',
    new_version_detected: 'Nouvelle version détectée',
    timeout: 'Délai dépassé'
  },
  lang: {
    fr: 'français',
    en: 'anglais',
    zh: 'chinois'
  },
  menu: {
    restaurants: 'Restaurants',
    monuments: 'À visiter',
    happenings: 'Animations',
    hours: 'Horaires',
    inbox: 'Messages',
    brands: 'Marques',
    exhibitors: 'Exposants',
    exhibitorsAndNp: 'Exposants & produits',
    services: 'Services',
    speakers: 'Inter-venants',
    map: 'Plan',
    agenda: 'Agenda',
    experiences: 'Animations',
    update: 'Mettre à jour',
    language: 'Langue',
    close: 'Fermer le menu',
    pmr: 'PMR',
    toggleProfile: 'Changer de profil',
    aircraftsAndFlights: 'Aéronefs & Vols',
    livewall: 'LiveWall',
    twitter: 'Twitter',
    location: 'Position',
    pmrQuestion: 'Êtes-vous une personne à mobilité réduite ?',
    pmrQuestion2: 'Itinéraire pour personne à mobilité réduite',
    cgu: 'Politique de confidentialité',
    printania: 'Printania'
  },
  home: {
    title: 'Accueil',
    myVisit: 'Ma visite',
    agenda: 'Agenda',
    experiences: 'Animations',
    exhibitors: 'Exposants',
    exhibitorsAndProducts: 'Exposants & Produits',
    mapsAndGroups: 'Plan & Groupes',
    map: 'Plan',
    aircraftsAndFlights: 'Aéronefs & Vols',
    pmr: 'PMR',
    practicalInfo: 'Infos pratiques',
    medias: 'Social Media',
    cgu: 'Politique de confidentialité'
  },
  profile: {
    title: 'Choix du profil',
    mainLabel: 'Sélectionnez votre profil',
    pro: 'PRO',
    gp: 'GRAND PUBLIC'
  },
  brand: {},
  exhibitor: {
    contacts: 'Contacts',
    relatedContacts: 'Contacts sur le salon',
    entities: 'Entités',
    agentCountries: 'Pays agents',
    brands: 'Marques',
    activities: 'Activités',
    categories: 'Catégories'
  },
  placeSearch: {
    editStart: 'Modifier départ',
    setDestination: 'Etape suivante',
    itinerary: 'Itinéraire',
    start: 'Choisissez votre point de départ',
    destination: 'Destination',
    poi: "Point d'intérêt"
  },
  aircraft: {
    pilot: 'Pilote',
    coPilot: 'Co-pilote',
    country: 'Pays',
    manufacturer: 'Constructeur',
    category: 'Catégorie',
    usage: 'Utilisation',
    wingSpan: 'Envergure',
    length: 'Longueur',
    emptyWeight: 'Tonnage',
    maxCruiseSpeed: 'Vitesse max. de croisière',
    characteristics: 'Caractéristiques',
    messageNoFlightYet: 'Cet aéronef est prévu dans un programme en vol. Information à venir ultérieurement'
  },
  newproducts: {
    launchDate: 'Mise sur le marché '
  },
  events: {
    to: 'à',
    access: 'Accès',
    publics: 'Public'
  },
  mobigeo: {
    title: 'Carte',
    goTo: 'Y aller',
    shouldReload: 'Les données du plan ont été mises à jour, souhaitez-vous le recharger maintenant ?',
    reloadDueToUpdate: 'Mise à jour du plan…',
    errors: {
      // automatic matching. Refer to MobiGeo API doc to know the error codes
      ROUTE_ERROR_NO_ROUTE_FOUND: 'Aucun itinéraire trouvé.',
      ROUTE_ERROR_USER_HAS_NO_POSITION: "Vous n'êtes pas localisé."
    },
    bluetoothWarning: 'Vous devez activer le bluetooth dans les paramètres de votre téléphone',
    locationSettingWarning: 'Vous devez activer le service de localisation dans les paramètres de votre téléphone',
    goSettingsQuestionTitle: 'Permissions requises',
    goSettingsQuestion: 'Voulez-vous accéder aux paramètres pour autoriser la localisation ?'
  },
  favorites: {
    title: 'Favoris',
    download: 'Télécharger (excel)',
    yourFavs: 'Vos favoris',
    other: 'Autres'
  },
  papers: {
    title: 'Abstracts'
  },
  agenda: {
    title: 'Programme'
  },
  notes: {
    title: 'Note about',
    pageTitle: 'My notes',
    pageLinkTitle: 'Notes',
    delete: 'Delete the note',
    deleteAlert: 'Are you sure that you want to delete this note ?',
    exportButton: 'Export my notes',
    exportSuccess: 'Notes successfully sent by mail',
    errors: {
      INVALID_CREDENTIALS: 'Invalid credentials',
      INVALID_SMTP_CONFIGURATION: 'An error occured while sending the email',
      DEFAULT: 'An error occured while sending the email'
    },
    exportForm: {
      fields: {
        email: {
          label: 'Your email',
          validationMessage: 'Please enter a valid email address'
        }
      },
      title: 'Export your notes by email',
      subtitle: 'Please enter your email address and press the send button to receive your notes'
    }
  },
  medias: {
    title: 'Social Media',
    label: 'Suivez nos actualités sur'
  },
  pmr: {
    title: 'PMR'
  },
  search: {
    title: 'Recherche',
    placeholderSuffix: '... (min. 2 car.)',
    submitLabel: 'Ok',
    searchFor: 'Rechercher des '
    // keywords: 'Mots clés',
  },
  speaker: {
    relatedInterventions: 'Interventions'
  },
  intersticiel: {
    closeLabel: 'Skip Ad'
  },
  promoMs: 'App développée par Mobile-Spot',
  inbox: {
    title: 'Messages',
    empty: "Vous n'avez pas de message",
    dismiss: 'Ignorer',
    confirmNotif: 'OK'
  },
  login: {
    title: 'Identifiez-vous',
    fillTheFields: 'Merci de saisir votre identifiant et mot de passe avant de valider',
    badLogin: 'Votre identifiant ou mot de passe est incorrect',
    loginText: 'Identifiant',
    passwordText: 'Mot de passe',
    loginButton: 'Valider',
    loginSuccess: 'Vous êtes connecté',
    loginToSyncYourFavs: 'Entrez les mêmes identifiants créés lors de votre demande de badge pour synchroniser vos favoris entre la version web eurosatory.com et l’application mobile !',
    introductoryText: 'Vos favoris entre vos différentes sessions web et mobile se synchronisent automatiquement.',
    forgottenPassword: "J'ai oublié mon mot de passe",
    createAccount: 'Créer un compte',
    tos: "Conditions d'utilisation",
    profile: 'Profil',
    logout: 'Déconnexion',
    noNetwork: "Vous n'êtes pas connecté à Internet",
    loading: 'Vérification...'
  },
  userData: {
    title: 'Profil',
    notice: 'La mise à jour nécessite une connexion internet, et quelques minutes peuvent être nécessaires avant sa prise en compte',
    fields: {
      title: 'Civilité',
      firstName: 'Prénom',
      lastName: 'Nom',
      organizationName: 'Organisation',
      position: 'Rôle',
      email: 'Email',
      phone: 'Téléphone',
      visible: 'Visible',
      contactable: 'Contactable'
    },
    picture: {
      title: 'Photo de profil',
      selectYours: 'Sélectionnez votre photo',
      pickError: 'Erreur rencontrée lors du traitement de votre photo',
      confirmRemoval: 'Confirmez-vous la suppression de votre photo ?',
      confirmSubstitution: 'Vous allez remplacer votre photo de profil.<br>Confirmez-vous cette action ?'
    },
    updateSuccess: 'Sauvegarde effectuée',
    yourProfile: 'Votre profil',
    yourEvents: 'Vos interventions',
    contactedParticipantsSearchPlaceholder: 'Recherche de participants contactés',
    externalResources: 'Votre feuille de route',
    noNetwork: "Vous n'êtes pas connecté à Internet",
    metContacts: 'Contacts rencontrés',
    contactsSearchPlaceholder: 'Recherche parmi vos contacts'
  },
  appointment: {
    button: 'Mise en relation',
    setProfileFirst: 'Vous devez renseigner votre profil pour pouvoir utiliser cette fonctionnalité.',
    sent: (formatedDate, email, phone) => "Demande envoy\xE9e ".concat(formatedDate, ". Votre destinataire vous r\xE9pondra par courriel \xE0 l\u2019adresse ").concat(email).concat(phone ? " ou par tel au ".concat(phone) : '', "."),
    errors: {
      GENERIC: "Il n'a pas été possible d'envoyer la demande de mise en relation.",
      INVALID_REQUEST: "Une erreur est survenue lors de l'envoi de la demande de mise en relation.",
      EXHIBITOR_NOT_FOUND: "Une erreur est survenue lors de l'envoi de la demande de mise en relation.",
      EXHIBITOR_HAS_NO_CONTACT_EMAIL: "Une erreur est survenue lors de l'envoi de la demande de mise en relation.",
      ALREADY_SENT: 'Une demande de mise en relation a déjà été envoyée pour cet exposant.'
    }
  },
  synchroFavs: {
    codePlaceholder: 'Entrer un code',
    favoritesPageNoticeUnauthorizedMode: 'Vous pouvez synchroniser vos favoris entre vos différentes sessions web et app mobile. Pour cela cliquez sur',
    favoritesPageNoticeExtraUnauthorizedMode: 'Vos données de favoris ne sont ni exploitées pour quelque fin que ce soit, ni partagées avec un tiers. Vous pouvez arrêter la synchronisation à tout moment en appuyant sur le bouton',
    favoritesPageNotice: 'Quand vous êtes authentifié, vos favoris se synchronisent entre vos sessions web et mobile.',
    logoutConfirm: 'Vos favoris ne sont plus synchronisés sur ce terminal, voulez-vous les conserver en local ou les effacer ?',
    logoutConfirmUnauthorizedMode: 'La synchronisation sur ce terminal va être arrêtée. Voulez-vous effacer ou conserver vos favoris en local ?',
    status: {
      ongoing: 'Synchronisation des favoris en cours',
      failed: 'Vos favoris ne sont pas synchronisés. Vérifiez votre connexion de données',
      success: 'Vos favoris sont synchronisés'
    },
    haveCode: "J'ai un code",
    noCode: "je n'ai pas de code",
    syncCode: 'Code de synchronisation',
    localSyncCode: {
      singular: 'Code de synchronisation utilisé',
      plural: 'Codes de synchronisation utilisés'
    },
    enterCodeNotice: 'Entrez votre code pour synchroniser vos favoris',
    codeCreatedNotice: ' Le code ci-dessous vous a été attribué. Faites OK pour synchroniser vos favoris sur ce terminal',
    invalidCode: 'Code invalide',
    loadingCode: 'Chargement du code ...',
    comfirmDisableSynchro: 'Voulez vous arrêter la synchronisation de vos favoris ?',
    favoritesPageNoticeCodeSynchronised: 'Synchonisé avec le code',
    onlyDigits: 'Chiffres uniquement'
  },
  poll: {
    listTile: 'Sondages disponibles',
    noPolls: 'Aucun sondage disponible',
    sessionTitle: 'Session rating',
    sessionCodeError: 'CODE INVALIDE',
    fillTheFields: "Merci d'entrer votre code",
    code: 'code',
    enterCode: "Merci d'entrer le code de session",
    pleaseRate: "Merci d'évaluer",
    back: 'Retour',
    next: 'Suivant',
    commentPlaceholder: 'Votre commentaire',
    thank: 'Merci',
    submit: 'Soumettre',
    submitted: 'Déjà soumis',
    done: 'Terminé',
    noNetwork: 'Réseau indisponible. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.',
    willOpen: 'Ce sondage sera ouvert le',
    willClose: 'Ce sondage sera clos le',
    isClosed: 'Ce sondage est clos',
    requireAuthentication: 'Merci de vous authentifier.',
    started: 'Commencé',
    notStarted: 'Non commencé',
    serverError: 'Erreur Serveur. Vous pouvez annuler et soumettre plus tard. Vos réponses ont été sauvegardées.'
  },
  docunit: {
    documents: 'Documents',
    document: 'Document',
    contacts: 'Contacts',
    presskit: 'Casier presse'
  },
  filterDialog: {
    title: 'Filtrer',
    clear: 'Réinitialiser'
  },
  klipsoLeads: {
    title: 'Klipso Leads',
    androidScannerText: 'Placer le QR code dans le zone',
    scanError: 'Le scan a échoué',
    scanDenied: "Accès à l'appareil photo refusé.",
    disclaimer: "<p>En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :</p><ul><li>Son consentement est lié à son acceptation de se faire scanner son badge.</li><li>L'ensemble des données fournies lors de son inscription (coordonnées, activité, fonction, ..) à la manifestation sont susceptibles de vous être transmises (hors identifiants et mot de passe de connexion).</ul><p>Vous vous engagez à donner la possibilité de rectifier ou supprimer ses données dès que vous en aurez la possession, à chacun de vos contacts.</p><p>Vous vous engagez à respecter la confidentialité des données, de chacun de vos contacts, dès que vous en aurez la possession.</p>",
    accept: "J'ACCEPTE",
    validate: 'VALIDER',
    back: 'RETOUR',
    invalidLicence: 'Licence non valide',
    fields: {
      licence: 'Clé de licence',
      userName: "Nom d'utilisateur"
    },
    emptyLicenceOrUsername: "Veuillez saisir votre licence et votre nom d'utilisateur",
    allContactsSync: ' tous synchronisés',
    contactsNotSync: ' contacts non synchronisés',
    sortByDate: 'Trier par date',
    sortByCompany: 'Trier par société',
    listEmpty: "Aucun contact pour l'instant",
    disabledScanButtonMessage: 'Le salon est terminé.',
    cancel: 'ANNULER',
    save: 'ENREGISTRER',
    formTitle: 'QUESTIONNAIRE',
    formNotifMandatoryFieldsToSet: "Des champs obligatoires n'ont pas été saisis.",
    historyTitle: 'Historique',
    searchPlaceholder: 'Prénom, nom, société…',
    ongoingSync: 'Synchronisation en cours…',
    resetLicence: 'Réinitialiser / Changer de licence',
    resetLicenceWithNonSyncContacts: "Vous ne pouvez pas changer votre clé de licence s'il y a des contacts non synchronisés",
    resetLicenceConfirm: 'Confirmez-vous la réinitialisation de votre clé de licence?',
    exportContacts: 'Exporter mes contacts'
  },
  contacts: {
    scanError: 'Le scan a échoué',
    scanDenied: "Accès à l'appareil photo refusé.",
    qrCodeUnHnadled: 'QRCode non reconnu',
    disclaimer: "<p>Ce lecteur de badge vous permet de scanner le QR-code présent sur le badge d'un autre participant pour pré-remplir une fiche contact que vous pourrez sauvegarder dans l'application (Mon Profil / onglet Mes Contacts) et exporter dans les contacts de votre smartphone.</p><p>Vous devez impérativement demander à la personne la permission de scanner son badge avant de le faire.</p><p>Les informations pré-remplies sont le nom, le prénom, l'organisation et la fonction de la personne (si disponibles). Pour obtenir une adresse email ou un téléphone, vous devrez le demander à la personne et l'ajouter dans le formulaire.</p><p>Avant de prendre une photo de la personne, vous devez impérativement lui demander l'autorisation de le faire</p>",
    disclaimerOk: "J'AI COMPRIS",
    disclaimerNOk: 'ANNULER',
    loginNeeded: 'L’usage du lecteur de badge nécessite que vous soyez authentifié',
    contactExist: 'Ce contact est déjà présent dans vos Contacts, voulez-vous le mettre à jour ?',
    photoPermission: 'Demandez l’autorisation à la personne avant prendre son portrait',
    form: {
      title: 'Contact',
      subTitle: '',
      fields: {
        profilePicture: 'Photo de profile',
        firstName: 'Prénom',
        lastName: 'Nom',
        organizationName: 'Organisation',
        role: 'Fonction',
        email: 'Email',
        phone: 'Tél. fixe',
        mobilePhone: 'Mobile',
        address: 'Adresse'
      }
    }
  },
  searchTaiga: {
    title: 'Recherche - ',
    search: 'Rechercher',
    goToSimpleSearch: 'Retour à la recherche simple',
    goToAdvancedSearch: 'Recherche avancée',
    editSearch: 'Modifier votre recherche',
    emptyParameters: 'Merci de renseigner au moins un champ',
    fields: {
      company: 'Compagnie',
      name: 'Nom',
      role: 'Rôle',
      type: 'Type',
      speaker: 'Intervenant',
      attendee: 'Participant'
    },
    fieldToggle: {
      byCompany: 'Par compagnie',
      byName: 'Par nom'
    }
  },
  formModal: {
    cancel: 'Cancel',
    send: 'Send'
  },
  modalPrivacyAgreement: {
    body: "<p>En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :En tant qu'utilisateur de cette application, vous avez l'obligation d'indiquer à votre contact que :</p",
    acceptButton: "Je suis d'accord",
    hint: 'Vous devez lire et accepter le contrat avant de continuer'
  }
};