module.exports = {
  AutoHideSplashScreen: {
    ios: 'false',
    android: 'true'
  },
  FadeSplashScreen: {
    ios: 'false',
    android: 'false'
  },
  FadeSplashScreenDuration: {
    ios: 0,
    android: 0
  },
  ShowSplashScreenSpinner: 'false',
  SplashScreenDelay: '0',
  AndroidPostSplashScreenTheme: '@style/Theme.AppCompat.NoActionBar',
  AndroidWindowSplashScreenAnimatedIcon: 'res/drawable-nodpi/ic_cdv_splashscreen.png',
  AndroidWindowSplashScreenAnimationDuration: '0',
  AndroidWindowSplashScreenBackground: '#ffffff',
  // issue tracker https://github.com/apache/cordova-android/issues/1485
  AndroidWindowSplashScreenBrandingImage: undefined,
  // issue tracker https://issuetracker.google.com/issues/194301890
  AndroidWindowSplashScreenIconBackgroundColor: '#ffffff',
  SplashShowOnlyFirstTime: 'true',
  SplashScreenBackgroundColor: '#ffffff',
  BackgroundColor: '#00D072',
  SplashScreenSpinnerColor: '#00D072',
  RemoveSplashScreenOnPause: 'false',
  SplashMaintainAspectRatio: 'false',
  ShowSplashScreen: 'false'
};